import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
  claimVortical,
  claimVorticalLoop,
  checkDopplers
} from "./utils/interact.js";


const Minter = (props) => {

  function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("👆🏽 Write a message in the text-field above.");
      } else {
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top button.");
      }
    });
  } else {
    setStatus(
      <p>
        {" "}
        🦊{" "}
        <a target="_blank" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    );
    }
  }

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [wizstatus, setWizStatus] = useState(""); 
  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address)
    setStatus(status);
    
        
    addWalletListener(); 
  }, []);

  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => { //TODO: implement
      const { status } = await mintNFT();
    setStatus(status);  
  };

  const onClaimPressed = async () => { //TODO: implement
      const { status } = await claimVortical();
  };

  const onClaimLoopPressed = async () => { //TODO: implement
      const { status } = await claimVorticalLoop();
  };

  const onCheckPressed = async () => { //TODO: implement
      const { wizstatus } = await checkDopplers(name);
    setWizStatus(wizstatus);  
  };

  return (
    <div className="Minter">
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>

      <br></br>
      <h1 id="title">Vorticals.</h1>
      <p>
       
      </p>
      

    <h2> Mint a Vortical for 0.02 ETH.</h2>
      <button id="mintButton" onClick={onMintPressed}>
        Mint!
      </button>
      <p id="status">
      </p>

      <br>
      </br>

    <h2> Own a Doppler? Claim your Vorticals.</h2>
          <br>
      </br>

    <h3> Check claim status here (enter Doppler #):</h3>
        <input id="entry"
          type="number"
          placeholder="Token ID"
          onChange={(event) => setName(event.target.value)}
        />
      <button id="checkButton" onClick={onCheckPressed}>
        Check
      </button>



        <p id="wizstatus">
        {wizstatus}
              </p>

      <br>
      </br>        

      <button id="mintButton" onClick={onClaimPressed}>
        Claim One
      </button>

      <button id="mintButton" onClick={onClaimLoopPressed}>
        Claim All
      </button>

    </div>
  );
};



export default Minter;
